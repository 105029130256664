import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as actions from '../../redux/layout.action';
import ISN from '../../../assets/Logo_ISN.svg';
import { Subtract, SubtractRed, SubtractDown, SubtractRight } from '../../../assets/images/web-isn';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import iconMenu from '../../../assets/images/icon-menu-mobile.svg';
import { NavigatorMobile } from './NavigatorMobile';
import { fetchCities } from '../../redux/layout.api';
import { getQueryString } from '../../../utils/params';


const Navigator = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const [dropdown, setDropdown] = useState([]);
  const { listProjects } = props.layout;
  const { language } = useParams();
  const refImg = useRef();
  useOnClickOutside(refImg);
  const history = useHistory();
  const [showNavigatorMobile, setShowNavigatorMobile] = useState(false);

  // const handleCloseNavigatorMobile = () => setShowNavigatorMobile(false);
  const handleShowNavigatorMobile = () => setShowNavigatorMobile(true);
  function useOnClickOutside(ref) {
    useEffect(
      () => {
        const listener = event => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          setDropdown([]);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      //eslint-disable-next-line
      [refImg],
    );
  }

  const currentMenu = [
    {
      link_to: `/${language}`,
      link_label: `${t('home_page.menu_home')}`,
      active: location.pathname === `/${language}` || location.pathname === `/${language}/`,
      isShow: location.pathname === `/${language}` || location.pathname === `/${language}/` ? false : true,
    },
    {
      link_to: `/${language}/serviced-apartments?pageIndex=1&pageSize=15&orderBy=desc&sortBy=0`,
      link_label: `${t('home_page.menu_serviced_apartment')}`,
      active:
        location.pathname.includes(`/serviced-apartments`) || location.pathname.includes(`/featured-projects/detail`),
      isShow: true,
    },
    {
      link_to: `/${language}/serviced-office/overview`,
      link_label: `${t('home_page.menu_serviced_office')}`,
      active: location.pathname.includes(`/serviced-office`),
      isShow: true,
    },
    {
      link_to: `/${language}/useful-information/about-renting-an-apartment-office-in-vietnam/area-guide`,
      link_label: `${t('home_page.menu_useful_information')}`,
      active: location.pathname.includes(`/useful-information`),
      isShow: true,
    },
    {
      link_to: `/${language}/offers?pageIndex=1&pageSize=10&orderBy=desc&sortBy=0`,
      link_label: `${t('home_page.menu_offers')}`,
      active: location.pathname.includes(`/offers`),
      isShow: true,
    },
    {
      link_to: `/${language}/blogs/all-blogs?pageIndex=1`,
      link_label: `${t('home_page.menu_blog')}`,
      active: location.pathname.includes(`/blog`),
      isShow: true,
    },
    {
      link_to: `/${language}/for-owners`,
      link_label: `${t('home_page.menu_for_owners')}`,
      active: location.pathname.includes(`/for-owners`),
      isShow: true,
    },
    {
      link_to: `/${language}/about-us`,
      link_label: `${t('home_page.menu_about_us')}`,
      active: location.pathname.includes(`/about-us`),
      isShow: true,
    },
  ];

  useEffect(() => {
    props.fetchProjectsRequest({ language });
    props.fetchLanguagesRequest();
    props.fetchCurrencyRequest();
    props.fetchCitiesRequest({ language });
    props.fetchListConditionRequest({ language });
    props.fetchRangeSettingRequest();
    // eslint-disable-next-line
    //eslint-disable-next-line
  }, []);

  const openProjectArea = id => {
    let listDropdown = [];
    if (dropdown && dropdown.includes(id)) listDropdown = dropdown.filter(i => i !== id);
    else listDropdown = [...dropdown, id];
    setDropdown(listDropdown);
  };

  const goToApartment = (city) => {
    localStorage.setItem('LOCATION_CITY', city);
    fetchCities(getQueryString({ language })).then((res) => {
      if (res && res.locations.length > 0) {
        const cityCode = res.locations.filter(ele => ele.cityId === city)
        if(cityCode) {
          localStorage.setItem('LOCATION_CODE_CITY', cityCode[0].code);
          history.push(`/${language}/serviced-apartments?pageIndex=1&pageSize=15&orderBy=desc&sortBy=0`);
          window.location.reload();
        }

      }
    });

  }

  const handleCloseDropMenu = item => {
    history.push(`/${language}/featured-projects/detail/overview/${item.id}/${item.slug}`);
    setDropdown([]);
  };

  const DropdownItems = () => {
    return (
      <ul ref={refImg} className="dropItems" id="dropScroll">
        {listProjects.map((item, i) => {
          return (
            <div key={i}>
              <div
                onMouseEnter={() => {
                  openProjectArea(item.cityId);
                }}
                onClick={() => {
                  goToApartment(item.cityId);
                }}
                className="d-flex mt-2"
              >
                <div className="icon-sub d-flex align-items-center justify-content-center">
                  <img src={dropdown.includes(item.cityId) ? SubtractRed : Subtract} alt="icon"></img>
                </div>
                <li className={dropdown.includes(item.cityId) ? 'text-sub-active ml-0' : 'text-sub ml-0'}>
                  {item.name}
                </li>
              </div>
              {dropdown.includes(item.cityId)
                ? item.projects.map((project, index) => (
                    <div key={index} className="d-flex align-items-center text-left mt-2">
                      <div className="icon-sub-child ">
                        <img src={SubtractRight} alt="icon"></img>
                      </div>
                      <div className="text-sub">
                        <span onClick={() => handleCloseDropMenu(project)} className="project-name">
                          {project.projectName}
                        </span>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          );
        })}
      </ul>
    );
  };
  const gotoPage = (event, link) => {
    event.preventDefault();
    history.push(link);
    if (link.includes(location.pathname)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <section
        className="section-header-logo header-logo jp-font-sawarabi-gothic"
        style={{ fontWeight: '500', height: '86px' }}
      >
        <div className="container pt-4 pb-3">
          <div className="row align-items-center d-flex bd-highlight">
            <div className="col-4 col-md-2">
              <div className="header-logo-image text-left">
                <a
                  href="/"
                  onClick={e => {
                    gotoPage(e, `/${language}`);
                  }}
                >
                  <LazyLoadImage className="w-100" alt="ISN Vietnam Housing" src={ISN} effect="blur" />
                </a>
              </div>
            </div>
            {/* <div className="menu-responsive-overlay show"></div> */}
            <div className="col-12 col-md-10 d-none d-lg-block bd-highlight ml-auto pl-0">
              <div className="nav-main-menu">
                <ul className="main-menu text-right">
                  {currentMenu.map((menu, i) => (
                    <li className={`menu-item showDropdown ${menu.active ? 'active' : ''} `} key={i}>
                      {menu.isShow === true ? (
                        <a href="/" onClick={e => gotoPage(e, menu.link_to)}>
                          {menu.link_label}
                        </a>
                      ) : (
                        ''
                      )}
                      {menu.link_label === `${t('home_page.menu_serviced_apartment')}` ? (
                        <span className="showIcon">
                          {menu.active ? (
                            <img
                              ref={refImg}
                              id="openDropdown"
                              src={SubtractRed}
                              alt="icon"
                              style={{ padding: '5px' }}
                            ></img>
                          ) : (
                            <>
                              <img
                                className="subtractDown"
                                ref={refImg}
                                id="openDropdown"
                                src={SubtractDown}
                                alt="icon"
                                style={{ padding: '5px' }}
                              ></img>
                            </>
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                      {menu.link_label === `${t('home_page.menu_serviced_apartment')}` ? <DropdownItems /> : ''}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-8 col-md-10 d-block d-lg-none">
              <div className="w-100 d-flex justify-content-end">
                <button onClick={() => handleShowNavigatorMobile()} className="btn-menu-mobile p-0 button-box-shadow-none">
                  <LazyLoadImage className="w-100" alt="button menu mobile" src={iconMenu} effect="blur" />
                </button>
                <NavigatorMobile
                  layout={props?.layout}
                  listProjects={listProjects}
                  show={showNavigatorMobile}
                  close={() => setShowNavigatorMobile(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProjectsRequest: actions.fetchProjectsRequest,
      fetchLanguagesRequest: actions.fetchLanguagesRequest,
      fetchCurrencyRequest: actions.fetchCurrencyRequest,
      fetchCitiesRequest: actions.fetchCitiesRequest,
      fetchListConditionRequest: actions.fetchListConditionRequest,
      fetchRangeSettingRequest: actions.fetchRangeSettingRequest,
      // eslint-disable-next-line
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
