import React, { useState, useRef, useMemo, useEffect } from 'react';
import * as actions from '../../redux/layout.action';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Close } from '../../../assets/images/web-isn';
import { useTranslation } from 'react-i18next';
import { SubtractRed, SubtractDown, Question, Globe, Wallet, Location } from '../../../assets/images/web-isn';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { ChevronDownIcon } from '../icons';

export const NavigatorMobile = props => {
  // const history = useHistory();
  const location = useLocation();
  const { language } = useParams();
  const { t, i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const refImg = useRef();
  const history = useHistory();
  const [dropdown, setDropdown] = useState([]);

  const getCityCode = localStorage.getItem('LOCATION_CODE_CITY') ? localStorage.getItem('LOCATION_CODE_CITY') : 'HN';
  const [cityName, setCityName] = useState(`${t('location_' + getCityCode)}`);
  const [currency, setCurrency] = useState({});

  const currentMenu = [
    {
      link_to: `/${language}/serviced-apartments?pageIndex=1&pageSize=15&orderBy=desc&sortBy=0`,
      link_label: `${t('home_page.menu_serviced_apartment')}`,
      active:
        location.pathname.includes(`/serviced-apartments`) || location.pathname.includes(`/featured-projects/detail`),
    },
    {
      link_to: `/${language}/serviced-office/overview`,
      link_label: `${t('home_page.menu_serviced_office')}`,
      active: location.pathname.includes(`/useful-information`),
    },
    {
      link_to: `/${language}/useful-information/about-renting-an-apartment-office-in-vietnam/area-guide`,
      link_label: `${t('home_page.menu_useful_information')}`,
      active: location.pathname.includes(`/blog`),
    },
    {
      link_to: `/${language}/offers?pageIndex=1&pageSize=10&orderBy=desc&sortBy=0`,
      link_label: `${t('home_page.menu_offers')}`,
      active: location.pathname.includes(`/offers`),
    },
    {
      link_to: `/${language}/blogs/all-blogs?pageIndex=1`,
      link_label: `${t('home_page.menu_blog')}`,
      active: location.pathname.includes(`/about-isn`),
    },
    {
      link_to: `/${language}/for-owners`,
      link_label: `${t('home_page.menu_for_owners')}`,
      active: location.pathname.includes(`/for-owners`),
    },
    {
      link_to: `/${language}/about-us`,
      link_label: `${t('home_page.menu_about_us')}`,
      active: location.pathname.includes(`/about-us`),
    },
  ];

  const { listLanguage, listCity, listCurrency } = props?.layout || {};
  const { defaultLocation, locations } = listCity || {};

  useEffect(() => {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
    //eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (defaultLocation && defaultLocation !== '') {
      localStorage.setItem('DEFAULT_CITY', defaultLocation?.defaultLocationId);
    }
    //eslint-disable-next-line
  }, [defaultLocation]);

  const handleCloseModal = () => {
    props.close();
  };

  const handleCloseDropMenu = item => {
    history.push(`/${language}/featured-projects/detail/overview/${item.id}/${item.slug}`);
    setDropdown([]);
    props.close();
  };

  useEffect(() => {
    let currencyDefault = listCurrency?.filter(el => el?.isDefault)[0];
    if (currencyDefault) {
      let currencyStorage = JSON.parse(localStorage.getItem('CURRENCY'));
      if (currencyStorage) {
        setCurrency(currencyStorage);
      } else {
        localStorage.setItem('CURRENCY', JSON.stringify(currencyDefault));
        setCurrency(currencyDefault);
      }
    }

    //eslint-disable-next-line
  }, [listCurrency]);

  const gotoPage = (event, link) => {
    event.preventDefault();
    history.push(link);
    if (link.includes(location.pathname)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    props.close();
  };

  const onClickPreventDefault = event => {
    event.preventDefault();
  };

  const onChangeLanguage = langPath => {
    const lang = langPath.split('/')[1];
    const listParams = location.pathname.split('/');
    if (lang !== language) {
      listParams[1] = lang;
      const newPathname = listParams.join('/');
      const refUrl = location.search.replace(/language=(.*?(jp|vi|en))/gi, `language=${lang}`);
      window.location.href = newPathname + refUrl;
    } else {
      return;
    }
  };

  const handleChangeCurrency = (e, item) => {
    if (item.code !== currency.code) {
      localStorage.setItem('CURRENCY', JSON.stringify(item));
      setCurrency(item);
      props.fetchCurrencyCurrent(item);
    }
  };

  const handleLang = () => {
    let lang = localStorage.getItem('language');
    if (listLanguage && listLanguage.length !== 0) {
      return (
        <ul className="wrap-dropdown-flag">
          {listLanguage.map((item, i) => {
            return (
              <li
                key={i}
                className="flag-item"
                style={{ backgroundColor: lang === item.code ? '#dadada' : '' }}
                onClick={() => onChangeLanguage('/' + item.code)}
              >
                <div>
                  <img
                    width="25%"
                    alt="flag-icon"
                    src={`${process.env.REACT_APP_ISN_IMAGE_URL}${item.flag_Image_Url}`}
                  />
                  <span className="item-link">{item.name}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const handleChangeLocation = async (id, cityCode) => {
    if (id && cityCode && locations && locations.length) {
      setCityName(`${t('location_' + cityCode)}`);
      await localStorage.setItem('LOCATION_CODE_CITY', cityCode);
      await localStorage.setItem('LOCATION_CITY', id);
      window.location.href = location.pathname + location.search.toString();
    }
  };

  const handleListCities = () => {
    let city = parseInt(localStorage.getItem('LOCATION_CITY') ?? localStorage.getItem('DEFAULT_CITY'));
    if (locations && locations.length > 0) {
      return (
        <ul className="wrap-dropdown-flag">
          {locations.map((item, i) => {
            return (
              <li
                onClick={() => handleChangeLocation(item.cityId, item.code)}
                className="flag-item"
                style={{ backgroundColor: city === item.cityId ? '#dadada' : '' }}
                key={i}
              >
                <div>
                  <span className="item-link">{item.cityName}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const handleCurrency = () => {
    if (listCurrency && listCurrency.length > 0) {
      return (
        <ul className="wrap-dropdown-flag">
          {listCurrency.map((item, i) => {
            return (
              <li
                className="flag-item"
                style={{ backgroundColor: currency?.code === item.code ? '#dadada' : '' }}
                key={i}
                onClick={e => {
                  handleChangeCurrency(e, item);
                }}
              >
                <div>
                  <span className="item-link">{item.code}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const showMenuTop = useMemo(() => {
    return (
      <div className="col-12">
        <div className="nav-menu-top">
          <ul className="menu-top mobile d-flex bd-highlight">
            <div className="d-flex flex-wrap mr-auto">
              <li>
                <a
                  href="/"
                  onClick={e => {
                    gotoPage(e, `/${language}/helps`);
                  }}
                  className="item-link"
                >
                  <img src={Question} alt="question-icon"></img>
                  <span className="font-noto-sans-jp">{t('home_page.menu_help')}</span>
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="item-link"
                  onClick={e => {
                    onClickPreventDefault(e);
                  }}
                >
                  <img src={Globe} alt="globe-icon"></img>
                  <span className="font-noto-sans-jp">{t('text_language')}</span>
                  <ChevronDownIcon />
                </a>
                {handleLang()}
              </li>
              <li>
                <a
                  href="/"
                  className="item-link"
                  onClick={e => {
                    onClickPreventDefault(e);
                  }}
                >
                  <img src={Wallet} alt="globe-icon"></img>
                  <span className="font-noto-sans-jp">{currency?.code}</span>
                  <ChevronDownIcon />
                </a>
                {handleCurrency()}
              </li>
              <li className={window.location.href.includes('/payment-summary/') ? 'd-none' : 'd-block'}>
                <a
                  href="/"
                  className="item-link"
                  onClick={e => {
                    onClickPreventDefault(e);
                  }}
                >
                  <img src={Location} alt="location-icon"></img>
                  <span className="font-noto-sans-jp">{cityName}</span>
                  <ChevronDownIcon />
                </a>
                {handleListCities()}
              </li>
            </div>
          </ul>
        </div>
      </div>
    );
    //eslint-disable-next-line
  }, [locations, cityName]);

  const openProjectArea = id => {
    let listDropdown = [];
    if (dropdown && dropdown.includes(id)) listDropdown = dropdown.filter(i => i !== id);
    else listDropdown = [...dropdown, id];
    setDropdown(listDropdown);
  };

  const DropdownItems = () => {
    return (
      <React.Fragment>
        <ul ref={refImg} className="dropItems" id="dropScroll">
          {props.listProjects.map((item, i) => {
            return (
              <div key={i}>
                <div
                  onMouseUp={() => {
                    openProjectArea(item.cityId);
                  }}
                  className="d-flex mt-2"
                >
                  <li className={dropdown.includes(item.cityId) ? 'text-sub-active ml-0' : 'text-sub ml-0'}>
                    {item.name}
                  </li>
                  <div className="pl-1 icon-sub d-flex align-items-center justify-content-center">
                    <img src={dropdown.includes(item.cityId) ? SubtractRed : SubtractDown} alt="icon"></img>
                  </div>
                </div>
                {dropdown.includes(item.cityId)
                  ? item.projects.map((project, index) => (
                      <div key={index} className="d-flex align-items-center text-left mt-2">
                        <div className="text-sub">
                          <span onMouseUp={() => handleCloseDropMenu(project)} className="project-name">
                            {project.projectName}
                          </span>
                        </div>
                      </div>
                    ))
                  : ''}
              </div>
            );
          })}
        </ul>
      </React.Fragment>
    );
  };
  return (
    <>
      <Modal
        backdrop={false}
        className="modal-height-90 d-block d-lg-none right"
        show={props.show}
        onHide={handleCloseModal}
      >
        <div className="container h-100 d-block d-lg-none">
          <div className="row">
            <div className="close-btn-mobile" onClick={handleCloseModal}>
              <img src={Close} alt="close-icon" />
            </div>
          </div>
          <div className="col-10 mt-2 pl-0">{t('home_page.welcome')}</div>
          <div className="row mt-3">
            {showMenuTop}
            <div className="col-12 mt-2">
              <div className="nav-main-menu">
                <ul className="main-menu text-right">
                  {currentMenu.map((menu, i) => (
                    <li className={`menu-item menu-mobile d-block text-left m-0 ${menu.active ? 'active' : ''} `} key={i}>
                      <a href="/" onClick={e => gotoPage(e, menu.link_to)}>
                        {menu.link_label}
                      </a>
                      {menu.link_label === `${t('home_page.menu_serviced_apartment')}` ? (
                        <img
                          id="openDropdown"
                          src={showMenu || menu.active ? SubtractRed : SubtractDown}
                          alt="icon"
                          style={{ padding: '5px' }}
                          onClick={() => setShowMenu(!showMenu)}
                        ></img>
                      ) : (
                        ''
                      )}
                      {showMenu && menu.link_label === `${t('home_page.menu_serviced_apartment')}` ? (
                        <DropdownItems />
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProjectsRequest: actions.fetchProjectsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavigatorMobile);
